<template>
  <div class="info-rules">
    <section v-if="content.length" :data-containerid="`${type}_informations`">
      <h2 class="text-h3">{{ title }}</h2>

      <template v-for="item in content" :key="item?.sys.id">
        <h3 v-if="item.caption" class="text-h4 mt-md mb-xs">
          {{ item.caption }}
        </h3>

        <ContentfulRenderer class="content" :data="item.content" />
      </template>
    </section>

    <NoResults
      v-else
      :headline="$t('info.noresults.headline')"
      :subline="$t('info.noresults.subline')"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ContentBlock } from '~/@types/generated/cms/graphql-schema-types'
import type { InfoRouteType } from '~/constants/infoRoutes'

const props = defineProps<{ id: string; type: InfoRouteType }>()

const { data } = useContentfulContent().getRules(`${props.id}/info/rules`)

const content = computed<ContentBlock[]>(
  () => (data.value?.contentBlocksCollection?.items as ContentBlock[]) || [],
)
const title = computed(() => data.value?.title)
</script>

<style lang="scss" scoped>
.info-rules {
  :deep(.content) {
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: spacing('xs');
      font-size: $font-size-md;
    }

    dl,
    ol,
    ul {
      margin-bottom: spacing('md');
    }

    ol,
    ul {
      padding-left: spacing('md');
      list-style: unset;

      ::marker {
        color: color('secondary600');
      }
    }

    p {
      font-size: $font-size-caption;
    }
  }
}
</style>
